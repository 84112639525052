<template>
  <div class="headwrap">
    <div class="goback" @click="logout()">
      <img width="50" src="@/assets/img/goback.png" />
    </div>
  </div>
</template>



<script>
export default {
  data() {
    return {};
  },
  methods: {
    logout() {
        
      localStorage.clear();
      window.location.href = "http://uof-test.foodsmart.com.tw/UOFTEST/";
      
      //  客戶正式區
      // window.location.href = "https://uof.foodsmart.com.tw/";
      Notification({
        title: "成功",
        message: "已成功登出",
        type: "success",
        duration: 2000,
      });
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.headwrap {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99;
}
.headwrap .goback {
  z-index: 2;
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
  text-align: center;
  padding-top: 10px;

  img {
    width: 40px;
  }
}
</style>
