<template>
    <div>
        <Navbar />
        
        
        <router-view/>
    </div>
</template>


<script>
import Navbar from '@/components/Layout/Navbar.vue'
export default {
    components: {
        Navbar,
    }
}
</script>